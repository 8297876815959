import { types, getParent, flow, getSnapshot } from 'mobx-state-tree';
import { InventoryGroupRow, GroupArea, WhsArea } from './models';
import { sumBy, uniq, flatten, uniqBy } from 'lodash';
import { FilterStore } from './FilterStore';
import columns from '../../components/pages/inventory/InventoryList/columns';
import { AREA_MAP, COLUMN_NAME } from './constants';
import ColumnStore from '../column/ColumnStore';
import { resolvePaginationStore } from '../PaginationStore';
import { bol } from '../../services';

const DEFAULT_COLUMNS = columns.filter(c => c.defaultColumn).map(c => c.accessor);
const EXCELROWS = [
  'uid',
  'bol_id',
  'ib_number',
  'marking',
  'status_name',
  'total_actual_qty',
  'total_qty',
  'aging',
  'total_inbound_pallet_count',
  'total_whs_pallet_count',
  'total_shipout_pallet_count',
  'total_remaining_pallet_count',
  'delivery_code',
  'internal_warehouse_received_at',
  'bol_delivered_at',
  'whs_work_orders',
  'is_ready_to_ship',
  'load_type',
  'group_area',
  'total_estimate_pallet_count',
  'receiver_shipment_id',
  'memo',
  'total_weight_lb',
  'total_volume_cbm',
]

const EXCELHEADERROW = {
  'uid': 'Group#',
  'bol_id': 'BOL',
  'ib_number': 'CNTR#',
  'marking': 'WHS Marking',
  'status_name': 'Group Status',
  'total_actual_qty': 'Actual Qty',
  'total_qty': 'Booked Qty',
  'aging': 'Aging',
  'total_inbound_pallet_count': 'IB PLT',
  'total_whs_pallet_count': 'WHS PLT',
  'total_shipout_pallet_count': 'Shipout PLT',
  'total_remaining_pallet_count': 'Remain PLT',
  'delivery_code': 'DEL Code',
  'internal_warehouse_received_at': 'IB Date',
  'bol_delivered_at': 'OB Date',
  'whs_work_orders': 'WO',
  'is_ready_to_ship': 'Ready To Ship',
  'load_type': 'Load Type',
  'group_area': 'WHS Area',
  'total_estimate_pallet_count': 'EST PLT',
  'receiver_shipment_id': 'Shipment ID',
  'memo': 'Remark',
  'total_weight_lb': 'LBS',
  'total_volume_cbm': 'CBM',
}

const InventorysStore = types
  .model('InventorysStore', {
    inventorys: types.array(InventoryGroupRow),
    loading: false,
    selected: types.array(InventoryGroupRow),
    filter: types.optional(FilterStore, {}),
    column: types.optional(ColumnStore, { table: COLUMN_NAME, selected: DEFAULT_COLUMNS, }),
    groupArea: types.optional(GroupArea, {}),
    whsAreas: types.optional(types.array(WhsArea), []),
    pagination: types.optional(
      resolvePaginationStore({
        onChangePage: (self) => {
          getParent(self).clearLoadInventoryCount();
          getParent(self).loadInventorys();
        },
        onChangePerPage: (self) => {
          getParent(self).clearLoadInventoryCount();
          getParent(self).loadInventorys({ page: 1 });
        },
      }),
      {
        perPage: 100,
      },
    ),
    loadInventorysCount: 0,
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get selectedLoads() {
      return uniqBy(flatten(self.selected.map(s => s.loads ? s.loads : s).slice()), 'id');
    },
    get total_weight_lb() {
      return sumBy(self.inventorys, row => parseFloat(row.total_weight_lb || 0)).toFixed(2);
    },
    get total_volume_cbm() {
      return sumBy(self.inventorys, row => parseFloat(row.total_volume_cbm || 0)).toFixed(2);
    },
    get total_inbound_pallet_count() {
      return sumBy(self.inventorys, row => parseFloat(row.total_inbound_pallet_count || 0)).toFixed(2);
    },
    get total_whs_pallet_count() {
      return sumBy(self.inventorys, row => parseFloat(row.total_whs_pallet_count || 0)).toFixed(2);
    },
    get total_shipout_pallet_count() {
      return sumBy(self.inventorys, row => parseFloat(row.total_shipout_pallet_count || 0)).toFixed(2);
    },
    get total_estimate_pallet_count() {
      return sumBy(self.inventorys, row => parseFloat(row.total_estimate_pallet_count || 0)).toFixed(2);
    },
    get total_remaining_pallet_count() {
      return sumBy(self.inventorys, row => parseFloat(row.total_remaining_pallet_count || 0)).toFixed(2);
    },
    get total_qty() {
      return sumBy(self.inventorys, row => parseFloat(row.total_qty || 0)).toFixed(2);
    },
    get total_actual_qty() {
      return sumBy(self.inventorys, row => parseFloat(row.total_actual_qty || 0)).toFixed(2);
    },
    // get areas() {
    //   return AREA_MAP;
    // },
    get areas() {
      return self.whsAreas;
    }
  }))
  .actions(self => ({
    setSelected(selected) {
      self.selected = selected;
    },
    loadInventoryCount() {

    },
    clearLoadInventoryCount() {
      self.loadInventorysCount = 0;
    },
    advanceLoadInventoryCount() {
      self.loadInventorysCount++;
    },
    loadWhsArea: flow(function* () {
      let response = null;
      try {
        response = yield self.root.api.whsArea.index();
        self.whsAreas = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    addWhsArea: flow(function* (data) {
      self.loading = true;
      try {
        yield self.root.api.whsArea.store(data);
        self.loadWhsArea();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateWhsArea: flow(function* (id, data) {
      self.loading = true;
      try {
        yield self.root.api.whsArea.update(id, data);
        self.loadWhsArea();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    deleteWhsArea: flow(function* (id) {
      self.loading = true;
      try {
        yield self.root.api.whsArea.destory(id);
        self.loadWhsArea();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    setDefauleWhsArea: flow(function* (id) {
      self.loading = true;
      try {
        const response = yield self.root.api.whsArea.setDefauleWhsArea(id);
        self.whsAreas = response.data.data;
        // self.loadWhsArea();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    setDeliveryCodeMap: flow(function* (id, value) {
      if (typeof value !== 'string' || !value.match(/^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$/)) {
        self.root.ui.toast.error('Invalid format');
        return;
      }

      try {
        yield self.root.api.whsArea.setDeliveryCodeMap(id, value);
        self.loadWhsArea();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    setReadyToShip: flow(function* (id, value) {
      try {
        yield self.root.api.whsArea.setReadyToShip(id, {
          auto_assign_ready_to_ship: value,
        });
        self.loadWhsArea();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setHasWO: flow(function* (id, value) {
      try {
        yield self.root.api.whsArea.setHasWO(id, {
          auto_assign_whs_work_order: value,
        });
        self.loadWhsArea();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadInventorys: flow(function* ({ page } = {}) {
      try {
        self.loading = true;

        const filterValues = self.filter.toValues();

        const { data } = yield self.root.api.inventory.index({
          delivery_codes: filterValues.delCodes,
          container_numbers: filterValues.containers,
          markings: filterValues.markings,
          group_status: filterValues.groupStatus,
          area: filterValues.area,
          bols: filterValues.bols.map(b => b.substring(3)),
          is_ready_to_ship: filterValues.isReadyToShip,
          load_types: filterValues.loadTypes,
          page: page || self.pagination.page,
          per_page: self.pagination.perPage,
        });

        self.inventorys = data.data;
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      if (self.loadInventorysCount === 0) {
        self.applyOptionsToFilter();
      }

      self.advanceLoadInventoryCount();
    }),
    applyOptionsToFilter() {
      self.filter.setContainerStringArray(uniq(self.inventorys.map(i => i.ib_number).filter(i => i)));
      self.filter.setMarkingStringArray(uniq(self.inventorys.map(i => i.marking).filter(i => i)));
      self.filter.setDelCodeStringArray(uniq(flatten(self.inventorys.map(i => i.delivery_codes)).filter(i => i)));
      self.filter.setBolStringArray(uniq([].concat.apply([], self.inventorys.map(i => [].concat.apply([], i.bols.map(b => b.uid))).filter(i => i))));
    },
    adjustWhsPalletCount: flow(function* (loadIds, palletCount, reason) {
      let response = false;
      self.loading = true;

      try {
        response = yield self.root.api.inventory.adjustWhsPalletCount(loadIds, palletCount, reason);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;

      return response;
    }),
    transferToWms: flow(function* (groupIds, time) {
      let response = false;
      self.loading = true;

      try {
        response = yield self.root.api.inventory.transferToWms(groupIds, time);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;

      return response;
    }),
    loadArea: flow(function* (groupIds) {
      self.groupArea.setLoading(true);
      try {
        const response = yield self.root.api.inventory.area(groupIds);
        self.groupArea.setCollection(response.data.data);

      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.groupArea.setLoading(false);
    }),
    move: flow(function* (groupIds, areaId, time) {
      self.groupArea.setLoading(true);
      try {
        const response = yield self.root.api.inventory.move(groupIds, areaId, time);
        self.groupArea.setCollection(response.data.data);
        self.loadInventorys();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.groupArea.setLoading(false);
    }),
    toCSV(data, headers, separator = ',', enclosingCharacter = '"') {
      return (headers) ? `${headers.join(separator)}\n${data}` : data
    },
    exportRows(rows) {
      if (rows.length === 0) {
        self.root.ui.toast.error('Nothing to export');
        return
      }

      let _rows = rows.map((item) => {
        let row = [];
        item = {
          'uid': item['uid'],
          'bol_id': item['bols'].map(i => i.uid).join(' '),
          'ib_number': item['ib_number'],
          'marking': item['marking'],
          'status_name': item['status_name'],
          'total_actual_qty': item['total_actual_qty'],
          'total_qty': item['total_qty'],
          'total_inbound_pallet_count': item['total_inbound_pallet_count'],
          'total_whs_pallet_count': item['total_whs_pallet_count'],
          'total_shipout_pallet_count': item['total_shipout_pallet_count'],
          'total_remaining_pallet_count': item['total_remaining_pallet_count'],
          'delivery_code': item['delivery_codes'].join(' '),
          'internal_warehouse_received_at': item['inbounds'].map((i) => i.received_at).join(' '),
          'bol_delivered_at': item['delivered_bols'].map((i) => i.delivered_at).join(' '),
          'whs_work_orders': item['whs_work_orders'].map((i) => i.uid).join(' '),
          'is_ready_to_ship': item['is_ready_to_ship'] ? 'Yes' : 'Hold',
          'load_type': uniq(item['loads'].map(i => i.type_name)).join(' '),
          'group_area': item['area'],
          'total_estimate_pallet_count': item['total_estimate_pallet_count'],
          'receiver_shipment_id': item['receiver_shipment_ids'].join(' '),
          'memo': item['memo'],
          'total_weight_lb': item['total_weight_lb'],
          'total_volume_cbm': item['total_volume_cbm'],
        }

        EXCELROWS
          .forEach(i => {
            if (self.column.selected.includes(i)) {
              row.push(item[i])
            }
          })

        return row.join(',');
      })

      const csv = self.toCSV(_rows.join('\n'),
        EXCELROWS
          .filter(i => {
            return (self.column.selected.includes(i))
          }).map(i => EXCELHEADERROW[i]));
      const type = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'application/csv' : 'text/csv';
      const blob = new Blob(['\uFEFF', csv], { type });
      return blob;
    },
    exportInventories(selected) {
      if (selected) {
        return self.exportRows(self.selected);
      }
      return self.exportRows(self.inventorys);
    }
  }))


export default InventorysStore;
