import { types, getParent, getRoot } from 'mobx-state-tree';
import { isToday } from 'date-fns';
import { INBOUND_DEFAULT_CBM_PER_PALLET_RATIO, INBOUND_DEFAULT_WEIGHT_LBS_LIMIT_PER_PALLET_RATIO, INBOUND_SHIPMENT_TYPE_OCEAN } from './constants';
import { estimatePalletCount } from './helper';
import { sumBy } from 'lodash';
import WhsAlertTroubleShootStore from '../troubleShoot/TroubleShootStore';
import { AddonService } from '../addonService/models';

export const TypeCountItem = types
  .model({
    value: types.string,
    text: types.string,
    count: types.maybeNull(types.number),
  })
  .actions(self => ({
    isActive(type) {
      return self.value === type;
    },
    setCount(count) {
      self.count = count;
    },
  }));

export const InboundRowTruckerContacts = types
  .model({
    name: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
  });

export const InboundRowTrucker = types
  .model({
    id: types.number,
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    contacts: types.array(InboundRowTruckerContacts),
  });


export const InboundRow = types
  .model({
    id: types.number,
    uid: types.string,
    shipment_type: types.maybeNull(types.number),
    shipment_type_name: types.maybeNull(types.string),
    ib_shipment_number: types.maybeNull(types.string),
    truck_size: types.maybeNull(types.string),
    ir_eta: types.maybeNull(types.string),
    container_number: types.maybeNull(types.string),
    container_size: types.maybeNull(types.string),
    urgent: types.maybeNull(types.boolean),
    total_qty: types.maybeNull(types.union(types.number, types.string)),
    ready_to_pickup_at: types.maybeNull(types.string),
    delivery_date: types.maybeNull(types.string),
    received_at: types.maybeNull(types.string),
    empty_at: types.maybeNull(types.string),
    completed_at: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    is_new: types.maybeNull(types.boolean),
    canceled_at: types.maybeNull(types.string),
    is_outbound_pod: types.maybe(types.boolean),
    freight_released_at: types.maybeNull(types.string),
    customs_released_at: types.maybeNull(types.string),
    terminal_released_at: types.maybeNull(types.string),
    trouble_statuses: types.array(types.string),
    trucker: types.maybeNull(InboundRowTrucker),
    operator_name: types.maybeNull(types.string),
  })
  .views(self => ({
    get isAllReleased() {
      return self.freight_released_at
        || self.customs_released_at
        || self.terminal_released_at;
    },
    get isNew() {
      return self.is_new;
    },
  }))
  .actions(self => ({
    newDisappear() {
      getParent(self, 2).newDisappear(self.id);
    },
  }));


export const InboundDetailGroupLoadBol = types
  .model({
    id: types.number,
    uid: types.string,
    actual_pickup_time: types.maybeNull(types.string),
    is_pod: types.boolean,
  })


export const InboundDetailGroupLoadWorkOrder = types
  .model({
    id: types.number,
    uid: types.string,
    completed_at: types.maybeNull(types.string),
  })

export const InboundDetailGroupLoad = types
  .model({
    id: types.number,
    uid: types.string,
    delivery_codes: types.array(types.string),
    bols: types.array(InboundDetailGroupLoadBol),
    markings: types.array(types.string),
    whs_markings: types.array(types.string),
    customer_reference: types.maybeNull(types.string),
    receiver_shipment_id: types.maybeNull(types.string),
    has_whs_work_order: types.boolean,
    qty: types.maybeNull(types.union(types.number, types.string)),
    unit: types.maybeNull(types.string),
    volume_cbm: types.maybeNull(types.union(types.number, types.string)),
    weight_lb: types.maybeNull(types.union(types.number, types.string)),
    current_qty: types.maybeNull(types.union(types.number, types.string)),
    actual_qty: types.maybeNull(types.union(types.number, types.string)),
    inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    markup_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_work_orders: types.array(InboundDetailGroupLoadWorkOrder),
    is_oversize: types.boolean,
    is_overweight: types.boolean,
    is_ready_to_ship: types.boolean,
    type_name: types.maybeNull(types.string),
    memo: types.maybeNull(types.string),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get estimate_pallet_count() {
      const detail = self.root.inbound.detail;
      return estimatePalletCount(self, detail.cbmPerPalletRatio, detail.weightLbsLimitPerPallet);
    },

  }))
  .actions(self => ({
    switchIsReadyToShip(checked) {
      getParent(self, 4).updateReadyToShip([self.id], checked)
    },
  }))

export const InboundDetailGroup = types
  .model({
    loads: types.array(InboundDetailGroupLoad),
    markings: types.array(types.union(types.number, types.string)), // laravel collection group by will convert string to integer if the value is number
    delivery_codes: types.array(types.string),
    bols: types.array(InboundDetailGroupLoadBol),
    has_whs_work_order: types.boolean,
    qty: types.maybeNull(types.union(types.number, types.string)),
    units: types.array(types.string),
    volume_cbm: types.maybeNull(types.union(types.number, types.string)),
    weight_lb: types.maybeNull(types.union(types.number, types.string)),
    actual_qty: types.maybeNull(types.union(types.number, types.string)),
    inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    markup_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    memo: types.maybeNull(types.string),
    whs_work_orders: types.array(InboundDetailGroupLoadWorkOrder),
    empty_at: types.maybeNull(types.string),
    is_oversize: types.boolean,
    is_overweight: types.boolean,
    is_ready_to_ship: types.boolean,
    type_names: types.array(types.string),
    whsAreas: types.array(types.string),
  })
  .views(self => ({
    get estimate_pallet_count() {
      return sumBy(self.loads, 'estimate_pallet_count');
    },
    get load_ids() {
      return self.loads.map(l => l.id);
    },
  }))
  .volatile(self => ({
    print_pallet_count: '',
  }))
  .actions(self => ({
    setPrintPalletCount(v) {
      self.print_pallet_count = v;
    },
    autoSetPrintPalletCount() {
      self.print_pallet_count = Math.ceil(self.estimate_pallet_count);
    },
    viewLabel() {
      getParent(self, 2).viewLabel(self.load_ids);
    },
    printLabel() {
      getParent(self, 2).printLabel(self.load_ids);
    },
    updateGroupActualQty(actualQty) {
      getParent(self, 2).updateGroupActualQty(self.markings, self.delivery_codes, actualQty);
    },
    updateGroupInboundPalletCount(inboundPalletCount) {
      getParent(self, 2).updateGroupInboundPalletCount(self.markings, self.delivery_codes, inboundPalletCount);
    },
    updateGroupMarkupPalletCount(inboundPalletCount) {
      getParent(self, 2).updateGroupMarkupPalletCount(self.markings, self.delivery_codes, inboundPalletCount);
    },
    updateGroupWhsArea(whsArea) {
      getParent(self, 2).updateGroupWhsArea(self.markings, self.delivery_codes, whsArea);
    },
    updateGroupMemo(memo) {
      getParent(self, 2).updateGroupMemo(self.markings, self.delivery_codes, memo);
    },
    switchIsReadyToShip(checked) {
      getParent(self, 2).updateReadyToShip(self.loads.map(l => l.id), checked)
    },
  }))

export const InboundDetailItem = types
  .model({
    id: types.number,
    sku: types.maybeNull(types.string),
    marking: types.maybeNull(types.string),
    item_qty: types.maybeNull(types.union(types.number, types.string)),
    item_actual_qty: types.maybeNull(types.union(types.number, types.string)),
    carton_qty: types.maybeNull(types.union(types.number, types.string)),
    carton_actual_qty: types.maybeNull(types.union(types.number, types.string)),
    volume_cbm: types.maybeNull(types.union(types.number, types.string)),
    weight_lb: types.maybeNull(types.union(types.number, types.string)),
    inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    memo: types.maybeNull(types.string),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get estimate_pallet_count() {
      const detail = self.root.inbound.detail;
      return estimatePalletCount(self, detail.cbmPerPalletRatio, detail.weightLbsLimitPerPallet);
    },
  }))
  .volatile(self => ({
    cbmPerPalletRatio: INBOUND_DEFAULT_CBM_PER_PALLET_RATIO,
    weightLbsLimitPerPallet: INBOUND_DEFAULT_WEIGHT_LBS_LIMIT_PER_PALLET_RATIO,
  }))
  .actions(self => ({
    setPrintPalletCount(v) {
      self.print_pallet_count = v;
    },
    autoSetPrintPalletCount() {
      self.print_pallet_count = Math.ceil(self.estimate_pallet_count);
    },
    updateItemActualQty(actualQty) {
      getParent(self, 2).updateItemItemActualQty(self.id, actualQty);
    },
    updateCartonActualQty(actualQty) {
      getParent(self, 2).updateItemCartonActualQty(self.id, actualQty);
    },
    updateItemInboundPalletCount(inboundPalletCount) {
      getParent(self, 2).updateItemInboundPalletCount(self.id, inboundPalletCount);
    },
    updateMemo(memo) {
      getParent(self, 2).updateItemMemo(self.id, memo);
    },
    switchIsReadyToShip(checked) {
      getParent(self, 2).updateReadyToShip(self.loads.map(l => l.id), checked)
    },
  }))

export const InboundDetailLoad = InboundDetailGroupLoad
  .named('InboundDetailLoad')
  .actions(self => ({
    updateLoadMemo(memo) {
      getParent(self, 2).updateLoadMemo(self.id, memo);
    },
  }));

export const TradeParty = types.model({
  id: types.number,
  code: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})

export const InboundDetail = types
  .model({
    id: 0,
    uid: types.maybeNull(types.string),
    shipment_type: types.maybeNull(types.number),
    shipment_type_name: types.maybeNull(types.string),
    ib_shipment_number: types.maybeNull(types.string),
    truck_size: types.maybeNull(types.string),
    container_number: types.maybeNull(types.string),
    container_size: types.maybeNull(types.string),
    delivery_date: types.maybeNull(types.string),
    empty_at: types.maybeNull(types.string),
    received_at: types.maybeNull(types.string),
    completed_at: types.maybeNull(types.string),
    empty_by: types.maybeNull(types.string),
    received_by: types.maybeNull(types.string),
    completed_by: types.maybeNull(types.string),
    remark: types.maybeNull(types.string),
    feedback: types.maybeNull(types.string),
    group_total_qty: types.maybeNull(types.union(types.number, types.string)),
    group_total_volume_cbm: types.maybeNull(types.union(types.number, types.string)),
    group_total_weight_lb: types.maybeNull(types.union(types.number, types.string)),
    group_total_actual_qty: types.maybeNull(types.union(types.number, types.string)),
    group_total_inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    group_total_whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    group_total_markup_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    item_total_item_qty: types.maybeNull(types.union(types.number, types.string)),
    item_total_carton_qty: types.maybeNull(types.union(types.number, types.string)),
    item_total_volume_cbm: types.maybeNull(types.union(types.number, types.string)),
    item_total_weight_lb: types.maybeNull(types.union(types.number, types.string)),
    item_total_item_actual_qty: types.maybeNull(types.union(types.number, types.string)),
    item_total_carton_actual_qty: types.maybeNull(types.union(types.number, types.string)),
    item_total_inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    item_total_whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    groups: types.array(InboundDetailGroup),
    items: types.array(InboundDetailItem),
    created_at: types.maybeNull(types.string),
    canceled_at: types.maybeNull(types.string),
    operator_name: types.maybeNull(types.string),
    whs_trouble_shoot: types.maybeNull(WhsAlertTroubleShootStore),
    whs_loads: types.array(InboundDetailLoad),
    addon_services: types.array(AddonService),
    trucker_emails: types.array(types.string),
    cc_emails: types.array(types.string),
    unloading_team: types.maybeNull(TradeParty),
    // cbmPerPalletRatio: types.optional(types.union(types.number, types.string), INBOUND_DEFAULT_CBM_PER_PALLET_RATIO),
    // weightLbsLimitPerPallet: types.optional(types.union(types.number, types.string), INBOUND_DEFAULT_WEIGHT_LBS_LIMIT_PER_PALLET_RATIO),
  })
  .volatile(self => ({
    cbmPerPalletRatio: INBOUND_DEFAULT_CBM_PER_PALLET_RATIO,
    weightLbsLimitPerPallet: INBOUND_DEFAULT_WEIGHT_LBS_LIMIT_PER_PALLET_RATIO,
  }))
  .views(self => ({
    get isNew() {
      return self.created_at && isToday(new Date(self.created_at));
    },
    get group_total_estimate_pallet_count() {
      return sumBy(self.groups, 'estimate_pallet_count');
    },
    get item_total_estimate_pallet_count() {
      return sumBy(self.items, 'estimate_pallet_count');
    },
    get isOcean() {
      return self.shipment_type == INBOUND_SHIPMENT_TYPE_OCEAN;
    },
  }))
  .actions(self => ({
    exportExcel() {
      getParent(self).exportExcel(self.id);
    },
    exportV2() {
      getParent(self).exportV2(self.id);
    },
    viewPDF() {
      getParent(self).viewPDF(self.id);
    },
    viewExcel(type) {
      getParent(self).viewExcel(self.id, type);
    },
    printPDF() {
      getParent(self).printPDF(self.id);
    },
    viewLabel(loadIds) {
      getParent(self).viewLabel(self.id, loadIds);
    },
    printLabel(loadIds) {
      getParent(self).printLabel(self.id, loadIds);
    },
    viewMultipleLabels(pageSize) {
      getParent(self).viewMultipleLabels(self.id, pageSize);
    },
    printMutipleLabels() {
      getParent(self).viewMultipleLabels(self.id);
    },
    receive(confirmedDatetime) {
      getParent(self).receive(self.id, confirmedDatetime);
    },
    unreceive() {
      getParent(self).unreceive(self.id);
    },
    empty(data) {
      getParent(self).empty(self.id, data);
    },
    unempty() {
      getParent(self).unempty(self.id);
    },
    complete(completedAt) {
      getParent(self).complete(self.id, completedAt);
    },
    uncomplete() {
      getParent(self).uncomplete(self.id);
    },
    updateGroupActualQty(markings, deliveryCodes, actualQty) {
      getParent(self).updateGroupActualQty(self.id, markings, deliveryCodes, actualQty);
    },
    updateGroupInboundPalletCount(markings, deliveryCodes, inboundPalletCount) {
      getParent(self).updateGroupInboundPalletCount(self.id, markings, deliveryCodes, inboundPalletCount);
    },
    updateGroupMarkupPalletCount(markings, deliveryCodes, inboundPalletCount) {
      getParent(self).updateGroupMarkupPalletCount(self.id, markings, deliveryCodes, inboundPalletCount);
    },
    updateGroupWhsArea(markings, deliveryCodes, whsArea) {
      getParent(self).updateGroupWhsArea(self.id, markings, deliveryCodes, whsArea);
    },
    updateGroupMemo(markings, deliveryCodes, memo) {
      getParent(self).updateGroupMemo(self.id, markings, deliveryCodes, memo);
    },
    updateLoadMemo(loadId, memo) {
      getParent(self).updateLoadMemo(self.id, loadId, memo);
    },
    updateItemItemActualQty(itemId, actualQty) {
      getParent(self).updateItemItemActualQty(self.id, itemId, actualQty);
    },
    updateItemCartonActualQty(itemId, actualQty) {
      getParent(self).updateItemCartonActualQty(self.id, itemId, actualQty);
    },
    updateItemInboundPalletCount(itemId, inboundPalletCount) {
      getParent(self).updateItemInboundPalletCount(self.id, itemId, inboundPalletCount);
    },
    updateItemMemo(itemId, memo) {
      getParent(self).updateItemMemo(self.id, itemId, memo);
    },
    updateFeedback(feedback) {
      getParent(self).updateFeedback(self.id, feedback);
    },
    updateReadyToShip(loadIds, checked) {
      getParent(self).updateReadyToShip(self.id, loadIds, checked);
    },
    setFeedback(feedback) {
      self.feedback = feedback;
    },
    setCbmPerPalletRatio(cbmPerPalletRatio) {
      self.cbmPerPalletRatio = cbmPerPalletRatio;
    },
    setWeightLbsLimitPerPallet(weightLbsLimitPerPallet) {
      self.weightLbsLimitPerPallet = weightLbsLimitPerPallet;
    },

  }))
