import request from './request';
import FileSaver from 'file-saver';

export const inbound = {
  counts(params = {}) {
    return request.get('inbound/counts', { params });
  },
  index(params = {}) {
    return request.get('inbound', { params });
  },
  receive(id, confirmedDatetime) {
    return request.put(`inbound/${id}/receive`, { confirmed_datetime: confirmedDatetime });
  },
  unreceive(id) {
    return request.put(`inbound/${id}/unreceive`);
  },
  empty(id, data) {
    return request.put(`inbound/${id}/empty`, data);
  },
  unempty(id) {
    return request.put(`inbound/${id}/unempty`);
  },
  complete(id, completedAt) {
    return request.put(`inbound/${id}/complete`, { completedAt: completedAt });
  },
  uncomplete(id) {
    return request.put(`inbound/${id}/uncomplete`);
  },
  show(id, params = {}) {
    return request.get(`inbound/${id}`, { params });
  },
  updateGroupActualQty(id, groupBy, groupByValue, acutalQty) {
    return request.put(`inbound/${id}/updateGroupActualQty`, { group_by: groupBy, group_by_value: groupByValue, actual_qty: acutalQty });
  },
  updateGroupInboundPalletCount(id, groupBy, groupByValue, inboundPalletCount) {
    return request.put(`inbound/${id}/updateGroupInboundPalletCount`, { group_by: groupBy, group_by_value: groupByValue, inbound_pallet_count: inboundPalletCount });
  },
  updateGroupMarkupPalletCount(id, groupBy, groupByValue, palletCount) {
    return request.put(`inbound/${id}/updateGroupMarkupPalletCount`, { group_by: groupBy, group_by_value: groupByValue, markup_pallet_count: palletCount });
  },
  updateGroupWhsArea(id, groupBy, groupByValue, whsArea) {
    return request.put(`inbound/${id}/updateGroupWhsArea`, { group_by: groupBy, group_by_value: groupByValue, area_id: whsArea })
  },
  updateGroupMemo(id, groupBy, groupByValue, memo) {
    return request.put(`inbound/${id}/updateGroupMemo`, { group_by: groupBy, group_by_value: groupByValue, memo: memo });
  },
  updateLoadMemo(id, loadId, memo) {
    return request.put(`inbound/${id}/updateLoadMemo`, { load_id: loadId, memo: memo });
  },
  updateItemItemActualQty(id, itemId, acutalQty) {
    return request.put(`inbound/${id}/updateItemItemActualQty`, { item_id: itemId, actual_qty: acutalQty });
  },
  updateItemCartonActualQty(id, itemId, acutalQty) {
    return request.put(`inbound/${id}/updateItemCartonActualQty`, { item_id: itemId, actual_qty: acutalQty });
  },
  updateItemInboundPalletCount(id, itemId, inboundPalletCount) {
    return request.put(`inbound/${id}/updateItemInboundPalletCount`, { item_id: itemId, inbound_pallet_count: inboundPalletCount });
  },
  updateItemMemo(id, itemId, memo) {
    return request.put(`inbound/${id}/updateItemMemo`, { item_id: itemId, memo: memo });
  },
  syncGroupQtys(id, loadIds, type) {
    return request.put(`inbound/${id}/syncGroupQtys`, { loadIds: loadIds, type });
  },
  syncWhsArea(id) {
    return request.post(`inbound/${id}/syncWhsAreas`);
  },
  exportExcel(id, print) {
    return request.get(`inbound/${id}/excel`, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );

        // Extract filename from header
        const filename = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .replace(/^\"/, '')
          .replace(/\"$/, '')

        if (print) {
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          const w = window.open(fileURL, '_blank');
          w.print();
        } else {
          FileSaver.saveAs(file, filename);
        }
      });
  },
  exportV2(id, print = true) {
    return request.get(`inbound/${id}/exportV2`, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, '_blank');
        if (print) {
          w.print();
        }
      });
  },
  viewPDF(id) {
    return this.exportPDF(id, false);
  },
  exportReceiptExcel(id, type) {
    return request.get(`inbound/${id}/receiptExcel`, { responseType: 'blob', params: { type } })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );

        // Extract filename from header
        const filename = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .replace(/^\"/, '')
          .replace(/\"$/, '')


        FileSaver.saveAs(file, filename);

      });
  },
  printPDF(id) {
    return this.exportPDF(id, true);
  },
  exportPDF(id, print) {
    return request.get(`inbound/${id}/pdf`, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, '_blank');
        if (print) {
          w.print();
        }
      });
  },
  viewLabel(id, loadIds) {
    return this.exportLabel(id, loadIds, false);
  },
  printLabel(id, loadIds) {
    return this.exportLabel(id, loadIds, true);
  },
  exportLabel(id, loadIds, print) {
    return request.post(`inbound/${id}/label`, { load_ids: loadIds }, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, '_blank');
        if (print) {
          w.print();
        }
      });
  },
  viewMultipleLabels(id, data) {
    return this.exportMultipleLabels(id, data, false);
  },
  printMultipleLabels(id, data) {
    return this.exportMultipleLabels(id, data, true);
  },
  exportMultipleLabels(id, data, print) {
    return request.post(`inbound/${id}/multipleLabels`, data, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, '_blank');
        if (print) {
          w.print();
        }
      });
  },
  newDisappear(id) {
    return request.put(`inbound/${id}/newDisappear`);
  },
  updateFeedback(id, feedback) {
    return request.put(`inbound/${id}/updateFeedback`, { feedback: feedback });
  },
  truckers() {
    return request.get(`inbound/truckers`);
  },
  updateReadyToShip(id, loadIds, checked) {
    return request.put(`inbound/${id}/updateReadyToShip`, { load_ids: loadIds, checked })
  },

  updateUnloadingTeams(id, unloading_team_id) {
    return request.put(`inbound/${id}/updateUnloadingTeam`, { unloading_team_id })
  }
}
